.header {
  // padding: 2rem;
  // border: 1px solid #fff;
  // border-radius: 2rem;
  height: calc(80vh - 20px);
  background-color: #f4efde;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__container {
    background-color: var(--blue);
    width: 100%;
    // height: 100vh;
    // padding: 3rem 3rem 0;
    padding: 1rem 1rem 0;
  }
  
  &__scroll{
    position: absolute;
    bottom: 0;
    right: 1.5rem;
    z-index: 9;
    width: 75px;
    transform: translate(0, 50%);

    @screen lg{
      right: 0;
      width: 150px;
    }
  }

  &__video {
    position: relative;
    width: 100%;
    height: calc(100vh - 4rem);
    max-height: 100%;
    &::before,
    &::after {
      content: "";
      width: 100%;
      position: absolute;
      left: 0;
      z-index: 1;
    }
    lite-youtube {
      width: 100%;
      height: 100%;
      max-width: unset;
      .lty-playbtn {
        display: none;
      }
    }
  }

  &__title {
    margin-bottom: 2.875rem;
    display: block;
    max-width: 681px;
    width: 100%;
  }

  &__logos {
    display: flex;
    align-items: center;
    justify-content: center;

    &__separation {
      margin: 0 2rem;
    }
  }

  img {
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: cover;
    z-index: 2;
  }
}

.test {
  position: absolute;
  top: -1px;
  right: -1px;
  z-index: 4;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  color: #000;
  margin-top: 100px;
  margin-bottom: 0;
  padding: 0 0 2rem;

  p {
    margin: 10px 0;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;

    @screen lg {
      margin: 40px 0;
      font-size: 1rem;
    }
  }

  a {
    text-decoration: underline;
  }
}

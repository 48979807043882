

.finish{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;

  a{
    width: 80%;
    max-height: 80vh;
    // height: 100%;
    color: #000;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;

    @screen lg{
      font-size: 2rem;
    }
  }

  .button{
    background-color: #000;
    color: #fff;
    padding: 10px 32px 8px;
    border-radius: 100px;
    text-transform: uppercase;
    margin-top: 2rem;
  }

  svg{
    width: 100%;
    height: 100%;
    // flex: none;
  }
}
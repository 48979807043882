:root {
  --beige: #fff8f2;
  --secondary: #fff9d0;
  --alternative: #000;

  --blue: transparent;
  --ui-color: #000;
  --ui-alt-color: #000;

  --custom-input-border: #000;
  --custom-input-background: #000;

  --yellow: #fff039;
  --violet: #fff;
}

* {
  box-sizing: border-box;
}

body {
  background-color: var(--violet, #000);
  color: #000;
  font-family: "Helevetica";
}

section {
  padding: 0 1rem;
}

@media (min-width: 1024px) {
  section {
    padding: 0 2rem;
  }
}



.container{
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  position: relative;
}


@media (max-width: calc(1280px + 10px) ) {
  .container{
    max-width: calc(100% - var(--wrap-value));
   
  }
}



.response_selector{
  text-align: center;
  margin: 2rem 0;

  @screen lg{
    padding: 0 20px;
  }

  svg{
    display: inline-block;
    width: 100%;

    @screen lg{
      width: 70%;
    }
    // pointer-events: none;
    rect{
      fill: var(--blue);
      stroke: var(--ui-color, #fff);
    }

    path{
      fill: var(--ui-color, #fff);
    }

    circle{
      stroke: var(--ui-color, #fff);
      fill: transparent;
    }

    .response_selector--checked{
      circle{
        fill: var(--ui-color, #fff);
      }
    }

    rect,
    foreignObject{
      cursor: pointer;
    }

  }

  &__button{
    display: inline-flex;
   
  //   --size: 80px;
  //   // position: relative;
  //   display: inline-flex;
  //   align-items: center;
  //   padding: calc(var(--size) / 2) calc(var(--size) / 2) calc(var(--size) / 2) calc(var(--size) / 2);
  //   border-radius: 1000px;
  //   border: 2px solid #fff;
  //   color: #fff;
  //   text-transform: uppercase;
  //   background-color: var(--blue);
  //   cursor: pointer;
  //   font-size: 6vw;

  //   &::before{
  //     content: '';
  //     display: block;
  //     width: var(--size);
  //     height: var(--size);
  //     border: 2px solid #fff;
  //     border-radius: 1000px;
  //     margin-right: calc(var(--size) / 2);
  //   }

  //   &:hover{
  //     background-color: #000;
  //   }

  //   &--checked{
  //     color: #000;
  //     &::before{
  //       border-color: #000;
  //       background-color: #000;
  //     }

  //     &:hover{
  //       color: #fff;
        
  //       &::before{
  //         border-color: #fff;
  //       }
  //     }
  //   }
  }

  &__button + &__button{
    // margin-left: var(--size);
  }
}
.booking {
  &__title {
    font-weight: 300;
    line-height: 100%;
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 3rem;
    @screen sm {
      font-size: 4rem;
    }
    @screen lg {
      margin-top: 2.5rem;
      font-size: 100px;
      margin-bottom: 2.5rem;
    }
  }

  &__subtitle {
    font-size: 1.75rem;
    // text-align: center;
    @screen sm {
      font-size: 2.5rem;
    }
    @screen lg {
      font-size: 56px;
    }
  }

  &__submit_button {
    border: 0;
    // width: 100%;
    border-radius: 1000px;
    font-size: 24px;
    font-weight: 500;
    overflow: hidden;
    color: #fff;
    position: relative;
    background-color: #000;
    text-transform: uppercase;

    margin-top: 3rem;
    padding: 2rem 3rem;
    @screen sm {
      font-size: 32px;
      margin-top: 4rem;
      padding: 1rem 5rem;
    }
    @screen lg {
      margin-top: 5.75rem;
      padding: 1rem 7.25rem;
      font-size: 80px;
    }

    &--notcoming {
      margin-top: 1rem;

      @screen lg {
        margin-top: 2.5rem;
      }
    }
  }
}
